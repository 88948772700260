<template>
    <div id="App" v-if="state">
        <MainHeader />
        <main id="MainBody">
            <router-view></router-view>
        </main>
        <MainFooter />
        <a :href="whatsappLink" class="whatsapp-button" target="_blank" rel="noopener noreferrer">
            &#128172;
        </a>
    </div>
</template>
<script>
import MainHeader from "@/components/layout/header/MainHeader";
import MainFooter from "@/components/layout/footer/MainFooter";
import mixinConfiguracion from '@/libs/mixins/mixinConfiguracion'
import mixinUserdata from '@/libs/mixins/mixinUserdata'
export default {

    name: 'App',
    mixins: [mixinConfiguracion, mixinUserdata],
    components: {
        MainHeader,
        MainFooter
    },
    data() {
        return {
            state: false,
            telefono: "521234567890", // Número en formato internacional (Ejemplo: México -> 52 + número)
            mensaje: "Hola!! Deseo mas información: 📲✨",

        }
    },
    metaInfo: {
        title: 'Crmred'
    },
    mounted() {
        this.$store.dispatch('AppData/getData')
            .then(() => {
                this.state = true;
            });
    },

    computed: {
        whatsappLink() {
            const encodedMessage = encodeURIComponent(this.mensaje);
            return `https://api.whatsapp.com/send?phone=${this.telefono}&text=${encodedMessage}`;
        }
    },

    watch: {
        configuracion(config) {
            this.telefono = config.celular
            document.documentElement.style.cssText = `--tm-color:${config.color_primario}; --tm-cop:${config.color_secundario}; --tm-font:${config.font_color};`;
        }
    }
}
</script>
<style>
#MainBody {
    color: var(--tm-font);
}


.whatsapp-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #25D366;
    color: white;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    text-decoration: none;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease-in-out;
    z-index: 1000;
}

.whatsapp-button:hover {
    transform: scale(1.1);
}

@media (max-width: 600px) {
    .whatsapp-button {
        width: 50px;
        height: 50px;
        font-size: 25px;
    }
}

.section-page {
    padding-top: 35px;
    padding-bottom: 35px;
}

#MainBody figure {
    margin: 0;
}

#App .btn:not(.b-calendar .btn) {
    background: var(--tm-color);
    color: #fff;
    padding: 0.5em 1.2em;
    line-height: 1;
    border: none;
}

.titleDefault {
    display: flex;
    flex-direction: column;
    margin-bottom: 35px;
    font-size: 35px;
    text-transform: uppercase;
    font-weight: 700;
}

.titleDefault::after {
    content: '';
    display: block;
    width: 80px;
    border-bottom: 4px solid var(--tm-color);
    margin-top: 6px;
}

.sectionTemplate {
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: #ffffff;
    position: relative;
    z-index: 2;
}

.sectionTemplate:nth-last-of-type(even) {
    background: #f0f0f0;
    box-shadow:
        inset 0 10px 10px -10px #00000026,
        inset 0 -10px 10px -10px #00000026;
}

#MainBody {
    position: relative;
    z-index: 2;
    background: #fff;
    box-shadow: 0 10px 10px -10px #00000026;
}

@media(min-width:1400px) {
    .container-fluid.xxl {
        max-width: 1320px;
    }
}

@media(max-width: 991px) and (min-width: 768px) {
    .container-fluid.md {
        max-width: 720px;
    }
}

@media(max-width: 767px) {

    .container-fluid.xs,
    .container.xs {
        max-width: 340px;
    }

    body {
        font-size: 14px;
    }

    .sectionTemplate {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .titleDefault {
        font-size: 26px;
        margin-bottom: 25px;
    }
}

svg {
    display: block;
    width: 1.25em;
    height: 1em;
    color: inherit;
    fill: currentColor;
}
</style>
